<template>
  <v-dialog v-model="show" persistent max-width="290">
    <v-card>
      <v-card-title class="text-h5" color="green"> Confirmation </v-card-title>
      <v-card-text>
        <v-container class="d-flex flex-column"> {{ text }}</v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="$emit('close')"> Abort </v-btn>
        <v-btn color="green darken-1" text @click="doConfirm"> Confirm </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "ConfirmationDialog",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    doConfirm: function () {
      this.$emit("confirm");
    },
  },
});
</script>
