<template>
  <v-dialog v-model="show" persistent max-width="60%" min-width="300px">
    <v-card>
      <v-card-title class="text-h5">{{ title }}</v-card-title>
      <v-card-subtitle v-if="subtitle">{{ subtitle }}</v-card-subtitle>
      <v-card-text>
        <v-divider class="mb-5" />
        <v-container class="d-flex flex-column">
          <v-textarea
            filled
            label="Message"
            auto-grow
            outlined
            v-model="content"
          ></v-textarea>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="$emit('close')"> Abort </v-btn>
        <v-btn color="green darken-1" text @click="doPost"> {{ confirm || 'Post' }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditMessageDialog",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
    confirm: {
      type: String,
      required: false
    },
    value: {
      type: String
    }
  },
  computed: {
    content: {
      get() {
        return this.mutableContent || this.value;
      },
      set (newValue) {
        this.mutableContent = newValue;
      }
    }
  }, 
  methods: {
    doPost: function () {
      this.$emit("submit", {
        content: this.mutableContent,
      });
      this.mutableContent = "";
    },
  },
  data() {
    return {
      rules: [(v) => v.length <= 4096 || "Max 4096 characters"],
      mutableContent: "",
    };
  }
};
</script>
