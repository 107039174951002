<template>
  <div class="message-container mt-2 mb-2">
    <v-sheet
      shaped
      class="message-sidebar grey darken-3 d-flex flex-column align-center pt-2"
    >
      <router-link
        :to="`/profile/${message.poster_user_id}`"
        class="text-outline"
        >{{ message.forum_name }}</router-link
      >
      <v-avatar rounded size="100">
        <img
          :src="
            message.profile_picture ||
            'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541'
          "
        />
      </v-avatar>
      <span class="text-caption mb-2" v-if="message.flair">{{
        message.flair
      }}</span>
    </v-sheet>
    <v-sheet shaped outlined class="message-body pt-3">
      <div v-if="message.is_deleted">
        <h3 class="red--text">!! Message Deleted !!</h3>
      </div>
      <div v-html="compiledMessageContent"></div>
      <div v-if="message.is_deleted">
        <h3 class="red--text">!! Message Deleted !!</h3>
      </div>
    </v-sheet>
    <v-sheet
      rounded
      class="message-details mr-8 d-flex justify-center align-center px-2"
      color="teal"
      elevation="6"
    >
      <span v-if="message.edit_count <= 0"
        >Posted on
        {{ new Date(message.created_on * 1000).friendlyString() }}</span
      >
      <span v-else
        >Last edited on
        {{ new Date(message.created_on * 1000).friendlyString() }}</span
      >
    </v-sheet>
    <div class="message-actions ml-8">
      <v-btn
        class="mx-2"
        fab
        small
        color="primary"
        v-if="is_admin || message.poster_user_id == user_profile.id"
        @click="$emit('edit-message', message.id)"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <template v-if="is_admin">
        <v-btn
          class="mx-2"
          fab
          small
          color="error"
          @click="$emit('delete-message', message.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { marked } from "marked";
import * as DOMPurify from "dompurify";

export default {
  name: "ForumMessageCard",
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["is_admin", "user_profile"]),
    compiledMessageContent() {
      return DOMPurify.sanitize(marked(this.message.content));
    },
  },
};
</script>

<style scoped>
.message-container {
  display: grid;
  grid-template-rows: 1fr 1em 1em;
  grid-template-columns: 140px 1fr 1fr;
  padding-bottom: 2em;
}

.message-sidebar {
  grid-column: 1;
  grid-row: 1 / span 2;
  z-index: 2;
}

.message-body {
  grid-column: 1 / span 3;
  grid-row: 1 / span 2;
  z-index: 1;
  min-height: 150px;
  padding-left: 160px;
}

.message-details {
  grid-column: 3;
  grid-row: 2 / span 2;
  z-index: 3;
  opacity: 0.8;
}

.message-actions {
  grid-column: 2;
  grid-row: 2;
  z-index: 3;
}
</style>
