<template>
  <v-container
    fluid
    class="d-flex justify-center"
    v-if="!isLoading && thread && thread.title"
  >
    <div class="d-flex flex-column" style="min-width: 80%">
      <v-alert dense border="left" outlined type="warning" v-if="!is_logged_in">
        You must be <strong>logged in</strong> to post on the boards.
      </v-alert>
      <edit-message-dialog
        :show="show_edit_message"
        :title="editing_message.id ? 'Editing Post' : 'Replying To'"
        :subtitle="thread.title"
        v-model="editing_message.content"
        @close="show_edit_message = false"
        @submit="post_reply"
      />
      <confirmation-dialog
        @close="show_delete_message = false"
        :text="confirmation_text"
        @confirm="delete_message"
        :show="show_delete_message"
      />
      <v-sheet rounded>
        <v-breadcrumbs :items="breadcrumbs">
          <template v-slot:divider>
            <v-icon>mdi-forward</v-icon>
          </template>
        </v-breadcrumbs>
      </v-sheet>

      <h1>{{ thread.title }}</h1>
      <h2>{{ thread.description }}</h2>
      <v-divider class="ma-5" />
      <div class="d-flex justify-end mb-5" v-if="is_logged_in">
        <template v-if="is_admin">
          <v-btn class="mr-2" v-if="!thread.admin_write_only" color="error">
            Lock Thread
          </v-btn>
          <v-btn class="mr-2" v-if="thread.admin_write_only" color="success">
            Unlock Thread
          </v-btn>
          <v-btn class="mr-2" color="error"> Delete Thread </v-btn>
        </template>
        <v-btn
          color="primary"
          @click="show_edit_message = true"
          v-if="!thread.admin_write_only || is_admin"
        >
          New Reply
        </v-btn>
      </div>
      <forum-message-card
        v-for="message in thread_messages"
        :key="message.id"
        :message="message"
        @edit-message="click_edit_message"
        @delete-message="click_delete_message"
      />
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";

import {
  FETCH_BOARD_MESSAGES,
  FETCH_BOARD_THREAD,
  SUBMIT_THREAD_MESSAGE,
  EDIT_THREAD_MESSAGE,
  DELETE_THREAD_MESSAGE,
} from "@/store/actions.type";
import { BEGIN_LOADING, END_LOADING } from "@/store/mutations.type";

import ForumMessageCard from "@/components/Boards/ForumMessageCard.vue";
import EditMessageDialog from "@/components/Dialogs/EditMessageDialog.vue";
import ConfirmationDialog from "@/components/Dialogs/ConfirmationDialog.vue";

export default {
  name: "BoardThread",
  props: {
    threadId: {
      type: Number,
      required: true,
    },
  },
  components: {
    ForumMessageCard,
    EditMessageDialog,
    ConfirmationDialog,
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "thread_messages",
      "threads",
      "is_admin",
      "is_logged_in",
    ]),
    thread: function () {
      console.log(this.threads);
      return this.threads.find((t) => t.id == this.threadId);
    },
    breadcrumbs: function () {
      return [
        {
          text: "Boards",
          disabled: false,
          href: "/boards",
        },
        {
          text: this.thread.topic_title,
          disabled: false,
          href: `/boards/topic/${this.thread.topic_id}`,
        },
        {
          text: this.thread.title,
          disabled: true,
          href: `/boards/thread/${this.threadId}`,
        },
        {
          text: this.thread.description,
          disabled: true,
          href: "",
        },
      ];
    },
  },
  created() {
    store.commit(BEGIN_LOADING);
    Promise.all([
      store.dispatch(FETCH_BOARD_MESSAGES, this.threadId),
      store.dispatch(FETCH_BOARD_THREAD, this.threadId),
    ]).then(() => {
      store.commit(END_LOADING);
    });
  },
  methods: {
    post_reply: function (message_info) {
      if (!this.editing_message.id) {
        // Posting a new message.
        message_info.thread_id = this.threadId;
        store.commit(BEGIN_LOADING);
        store.dispatch(SUBMIT_THREAD_MESSAGE, message_info).then(() => {
          this.$router.go();
        });
      } else {
        // We're editing an existing message.
        store.commit(BEGIN_LOADING);
        store
          .dispatch(EDIT_THREAD_MESSAGE, {
            content: message_info.content,
            message_id: this.editing_message.id,
          })
          .then(() => {
            this.$router.go();
          });
      }
    },
    delete_message() {
      store.commit(BEGIN_LOADING);
      store.dispatch(DELETE_THREAD_MESSAGE, this.delete_message_id).then(() => {
        this.delete_message_id = 0;
        store.commit(END_LOADING);
      });
    },
    click_edit_message: function (message_id) {
      this.editing_message = this.thread_messages.find(
        (m) => m.id == message_id
      );
      this.show_edit_message = true;
    },
    click_delete_message: function (message_id) {
      this.delete_message_id = message_id;
      this.show_delete_message = true;
    },
  },
  data: () => ({
    show_edit_message: false,
    editing_message: {
      id: 0,
      content: "",
    },
    show_delete_message: false,
    delete_message_id: 0,
    confirmation_text: "Are you sure you want to delete that message?",
  }),
};
</script>
